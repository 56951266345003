import React, { useEffect, useState } from "react";
import { Icon } from "../../ui";
import { NavLink, useNavigate } from "react-router-dom";
import Button from "../../ui/elements/Button";
import dwdApk from "../../apk/frolicfuns.apk";

const Footer = () => {
  const [activeSection, setActiveSection] = useState(" ");
  const [isActive, setIsActive] = useState(false);
  const [scrolled, setScrolled] = useState(window.scrollY);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const scrollOnTop = () => {
    if (window.scrollY === 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollOnTop);
    return () => {
      window.removeEventListener("scroll", scrollOnTop);
    };
  }, []);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };
  const handleLinkClick = (link) => {
    setActiveSection(link);
    scrollToSection(link);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToSection = (div) => {
    const section = document.getElementById(div);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  // FOR ANIMATION
  useEffect(() => {
    setActiveSection("home");
    const sectionsForanimation = [
      {
        id: "whyfolicfuns",
        class: "futuresec_main",
        animationClass: "futureanimation",
      },
      {
        id: "Options",
        class: "supertrade_main",
        animationClass: "supertradanimation",
      },
      {
        id: "maxprofit",
        class: "maximum_profirsec",
        animationClass: "maximumanimation",
      },
    ];

    const handleScroll = () => {
      if (isActive) {
        setIsActive(false);
      }
      const scrollPosition = window.scrollY;
      const sections = document.querySelectorAll("section");

      sectionsForanimation.forEach((section) => {
        const sectionElement = document.getElementById(section.id);
        const element = document.querySelector("." + section.class);
        const rect = sectionElement?.getBoundingClientRect();
        if (rect?.top <= 450) {
          element.classList.add(section.animationClass);
          setActiveSection(sectionElement);
        } else if (rect?.top <= 0) {
          element.classList.remove(section.animationClass);
        }
      });
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;

        if (
          scrollPosition >= sectionTop - 180 &&
          scrollPosition < sectionTop + sectionHeight - 50
        ) {
          setActiveSection(section.id);
        }
      });
    };

    // Add animation class on initial load
    handleScroll();

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="enjoymain">
        <div className="container">
          <div className="enjoysub">
            <div className="enjoytext">
              <h5>Start Playing on FrolicFuns</h5>
              <p>
                What makes FrolicFuns so interesting? Why has it become so
                popular with cricket lovers?
              </p>
            </div>
            <a type="button" href={dwdApk} download className="primarybtn">
              Download Now
            </a>
          </div>
        </div>
      </div>
      <footer id="footer">
        <div className="footer">
          <div className="container">
            <div className="logodetail_main">
              <h4>Withdraw Winnings in 10 Seconds</h4>
              <div className="logodetail_sub">
                <Icon name="bankPayment" className="img-fluid" />
                <Icon name="upiPayment" className="img-fluid" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-4 col-6 my-2">
                <div className="logo-detail">
                  <div className="link-detail">
                    <a href="#home">
                      <Icon name="Logo" className="img-fluid mb-3" />
                    </a>
                    <p>
                      Owned and operated by VARINA IT SERVICES PRIVATE LIMITED.,
                      Frolicfuns.com is an online fantasy game designed for the
                      fans who like to showcase their cricket knowledge and
                      analytical skills.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 my-2">
                <div className="link-detail">
                  <h6>Useful Links</h6>
                  <p>
                    <a
                      onClick={() => {
                        toggleMenu();
                        handleLinkClick("home");
                      }}
                    >
                      Home
                    </a>
                  </p>
                  <p>
                    <a
                      onClick={() => {
                        toggleMenu();
                        handleLinkClick("Start");
                      }}
                    >
                      How to Play
                    </a>
                  </p>
                  <p>
                    <a
                      onClick={() => {
                        toggleMenu();
                        handleLinkClick("whyfolicfuns");
                      }}
                    >
                      Why FrolicFuns ?
                    </a>
                  </p>
                  <p>
                    <NavLink to={"/about-us"}>About us</NavLink>
                  </p>
                  <p>
                    <NavLink to={"/contact-us"}>Contact Us</NavLink>
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 my-2">
                <div className="link-detail">
                  <h6>Legal</h6>
                  <p>
                    <NavLink to={"/privacy-policy"}>Privacy Policy</NavLink>
                  </p>
                  <p>
                    <NavLink to={"/terms-condition"}>
                      Terms & Conditions
                    </NavLink>
                  </p>
                  <p>
                    <NavLink to={"/refund-return"}>
                      Refund & Cancellation
                    </NavLink>
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 my-2">
                <div className="link-detail">
                  <h6>Contact Info</h6>
                  <p>
                    <a href="mailto:itservicesvarina@gmail.com">
                      itservicesvarina@gmail.com
                    </a>
                  </p>

                  <p>
                    <a href="mailto:info@frolicfuns.com">info@frolicfuns.com</a>
                  </p>

                  {/* <div className="d-flex align-items-center gap-2 followus ">
                    <a href="">
                      <FaFacebook />
                    </a>
                    <a href="">
                      <FaTwitter />
                    </a>
                    <a href="">
                      <FaLinkedin />
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="copy-rightMain">
        <div className="container copy-right">
          <p>Copyright © 2024 FrolicFuns</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
